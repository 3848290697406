import Head from 'next/head';
import {
  BikeAndTreadmillPromo,
  ClubClasses,
  CustomerPost,
  FeaturesList,
  MainBanner,
  ZiYouBikePromo,
  SubscriptionCategories,
  Theme,
} from '~/components/marketing';
import Testimonials from '~/components/marketing/Testimonials';
import testimonials from '~/components/marketing/Testimonials/testimonialsData';
import { MainLayout } from '~/layouts';
import { getPublicPath } from '~/utils/getPublicPath';

export const Index = () => {
  const logo = getPublicPath('/logo-black.png');

  return (
    <MainLayout fluid>
      <Head>
        <title>ZiYou - Assinatura de Equipamentos Fitness</title>
        <meta
          name="description"
          content="Assinaturas mensais de esteiras, bicicletas ergométricas, bike spinning, elípticos, remos e estações de musculação. Conheça a ZiYou!"
        ></meta>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org","@type": "Organization","name": "ZiYou","url": "https://ziyou.com.br/","logo": "${logo}"}`,
          }}
        ></script>
      </Head>
      <MainBanner />
      <SubscriptionCategories componentId="home" />
      <ClubClasses />
      <BikeAndTreadmillPromo />
      <ZiYouBikePromo />
      <FeaturesList theme={Theme.DARK} />
      <Testimonials testimonialsList={testimonials.testimonialsHomeData} showRatings={true} />
      <CustomerPost />
    </MainLayout>
  );
};

export default Index;
