const testimonialsHomeData = [
  {
    authorName: 'Daniel Bruno ',
    testimonial:
      'Foi um processo muito simples, escolhi o produto no site, fizemos o agendamento e a entrega ocorreu conforme o esperado. Tudo muito transparente e sem surpresas. O equipamento veio novo, foi instalado respeitando os protocolos de segurança. Gostei muito, estão de parabéns!',
  },
  {
    authorName: 'Leonardo Pagani',
    testimonial:
      'Empresa cumpriu todos os prazos de tudo que foi combinado, me mantendo informado e avisando passo a passo.',
  },
  {
    authorName: 'Flavia Pinto',
    testimonial: 'Bike novinha e entrega super pontual. Até agora a experiência está sendo muito boa.',
  },
  {
    authorName: 'Fernanda Zapata',
    testimonial: 'Muito feliz e satisfeita. Já comecei a indicar para meus amigos.',
  },
  {
    authorName: 'C.Meireles',
    testimonial: 'Muito organizado, produto recebido impecável e excelente qualidade! Recomendo super!',
  },
  {
    authorName: 'Ricardo Akihiro',
    testimonial: 'Estou extremamente satisfeito com a ZIYOU!',
  },
  {
    authorName: 'Ana Paula',
    testimonial:
      'Recebi o equipamento e estou muito satisfeita desde meu primeiro contato com o atendimento para esclarecer minhas dúvidas até a entrega!!!',
  },
];

const testimonialsData = [
  {
    authorName: 'Andrea Noemia',
    testimonial:
      'O que mais amei na prestação de serviços da ZiYou é a facilidade com que tudo acontece: da escolha à entrega tudo feito de forma clara e online. A entrega muito rápida e com toda assessoria e instruções necessárias para o bom uso do aparelho. A esteira que escolhi é excelente e atende minhas necessidades, praticamente entregue nova e de muita qualidade e resistente. Encantada.',
  },
  {
    authorName: 'Rodolfo Rodrigues',
    testimonial:
      'Em um momento pessoalmente difícil para prática esportiva, com filho recém nascido e pós infecção por covid, a Ziyou tornou tudo mais simples. Em 2 dias consegui retomar o que estava procrastinando há pelo menos 2 meses.',
  },
  {
    authorName: 'Nadia Camila',
    testimonial:
      'Gostaria de registrar meu agradecimento e elogios aos que me atenderam quando eu e meus pais resolvemos alugar uma esteira para fazer exercícios em casa no site da ziyou. Entrega rápida, profissionais ótimos, explicaram tudo com muita atenção e nos ajudaram com a consultoria esportiva. Ter o conforto de poder caminhar em casa, com toda a segurança, e garantindo qualidade de vida é muito bom. Obrigada a toda equipe ziyou por proporcionar esse conforto.',
  },
  {
    authorName: 'Maria Helena',
    testimonial:
      'Tenho 75 anos, fiz duas cirurgias na coluna e vários tratamentos pra dor com muito pouco resultado. Agora que conheci a Ziyou e comecei treinar na bike me sinto muito mais confiante pra caminhar, as dores diminuíram sensivelmente, estou muito confiante e determinada! Gratidão aos eficientes profissionais da Ziyou! Obrigada a toda equipe por proporcionar esse conforto.',
  },
  {
    authorName: 'Priscila',
    testimonial:
      'Minha experiência com o equipamento que da ziyou está sendo ótima! A comodidade de poder treinar dentro de casa é perfeita. No dia da entrega o consultor esportivo foi muito atencioso e didático com a explicação de uso e dicas. Estou completamente satisfeita!',
  },
];

export default {
  testimonialsHomeData,
  testimonialsData,
};
